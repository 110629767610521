import sarougLogo from "../images/sarooj-white-logo.png";

import loading from "../images/loading.png";
import "../App.css";

function ComingSoon() {
  return (
    <div className="App-header">
      <div className="flex flex-col justify-center items-center w-[100%] h-[100%] md:px-[2%] ">
        <img className="pl-[3%]" src={sarougLogo} alt="coming-soon" />
        <h1 className="text-[4.5rem] lg:text-[8vw] font-[cairo] font-[500]">
          Coming Soon
        </h1>
        <img src={loading} alt="coming-soon" />
      </div>
    </div>
  );
}

export default ComingSoon;
