
import "./App.css";
import { Route, Routes } from "react-router";
import ComingSoon from "./Pages/ComingSoon";
import Home from "./Pages/Home";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import ContactUs from "./Pages/ContactUs";


function App() {
  return (
    <div>
      {/* <Navbar /> */}
      <Routes>
        {/* <Route path="/coming-soon" exact Component={ComingSoon} /> */}
        <Route path="/" exact Component={ComingSoon} />
        {/* <Route path="/contact-us" exact Component={ComingSoon} /> */}
      </Routes>
      {/* <Footer /> */}
    </div>
  );
}

export default App;
